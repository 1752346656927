function initMovie(videoSrc, elementId) {
  const video = document.getElementById(elementId || 'hls')
  video.volume = 0
  video.oncanplay = () => {
    video.play()
  }

  const config = {
    maxBufferLength: 12,
    maxBufferSize: 10 * 1000 * 1000,
  }

  if (Hls.isSupported()) {
    const hls = new Hls(config)
    hls.loadSource(videoSrc)
    hls.attachMedia(video)
  }
  else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    const hls = new Hls(config)
    video.src = videoSrc
  }
}
